import {
  CognitoIdentityProviderClient,
  ConfirmSignUpCommand,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import {
  BBPostMessageClient,
  EnvironmentModes,
  MessageTypes,
  Logger,
  LoginResult,
  SuccessfullLoginResult,
} from "bb-auth-frame/bb-post-message";

const STORED_TOKEN_ID = "bb-auth-frame-access-token";
const STORED_TOKEN_EXPIRES_AT_ID = "bb-auth-frame-access-token-expires-at";
const STORED_GUEST_REFERENCE_ID = "bb-auth-frame-guest-reference-id";
const STORED_TOKEN_IS_GUEST = "bb-auth-frame-access-token-is-guest";

export enum SocialSignInNetwork {
  Google = "Google",
  SignInWithApple = "SignInWithApple",
  Facebook = "Facebook",
}

export type CognitoAuthResult = {
  AccessToken: string;
  ExpiresIn: number;
  IdToken: string;
  NewDevicemetadata: {
    DeviceGroupKey: string;
    DeviceKey: string;
  };
  RefreshToken: string;
  TokenType: string;
};

export enum AuthPhase {
  VERIFYING_CREDENTIALS = "VERIFYING_CREDENTIALS",
  REGISTERING_TOKENS = "REGISTERING_TOKENS",
  CONFIRMING_ACCOUNT = "CONFIRMING_ACCOUNT",
  TRANSFERING_GUEST_USER = "TRANSFERING_GUEST_USER",
}

export const AuthPhaseMessages = {
  [AuthPhase.VERIFYING_CREDENTIALS]: "Checking your password",
  [AuthPhase.REGISTERING_TOKENS]: "Letting the website know who you are",
  [AuthPhase.CONFIRMING_ACCOUNT]: "Waiting for email confirmation",
  [AuthPhase.TRANSFERING_GUEST_USER]:
    "We're transfering the AI generated assets from your guest account to your new, permanent Buildbox account. This may take a minute or two.",
};

export type AuthPhaseCallback = (phase: AuthPhase, message: string) => void;

export interface FetchOptions {
  method: "post" | "get";
  path: string;
  includeCookies?: boolean;
  headers?: Record<string, string>;
  body?: any;
}

export class BBAuthClientBusyError extends Error {}
export class BBAuthUserNotConfirmedError extends Error {}

export interface CognitoEnv {
  /**
   * prod, dev, or local
   */
  AOB_ENV: string;
  /**
   * The user account management service base url (i.e. https://platform.buildbox.com)
   */
  UAM_BASE: string;
  /**
   * The cognito identity provider API URL
   */
  COG_URL: string;
  /**
   * The domain for the AOB specific cognito user pool (i.e. https://cognito-login.dev.8cell.com)
   */
  COG_DOMAIN: string;
  /**
   * the cognito app client ID
   */
  COG_CLIENT_ID: string;
  /**
   * The url of the bb-auth frame website (i.e. https://bb-auth.dev.8cell.com)
   */
  BB_AUTH_DOMAIN: string;
  /**
   * how long it takes an access token to expire in milliseconds
   */
  ACCESS_TIMEOUT_MILLISECONDS: number;
}

/**
 * The client / library for authorizing with cognito and passing tokens via BBPostMessage
 */
export class BBAuthClient {
  logger: Logger;
  readonly postMessageClient: BBPostMessageClient;
  readonly awsClient: CognitoIdentityProviderClient;
  readonly env: CognitoEnv;
  readonly guestReferenceIdGenerator?: () => string;
  consumedAuthCodes: Record<string, boolean> = {}; // this is to avoid attempts at re-consuming codes because of things like useEffect being called twice in react 18 strict mode

  refreshing: boolean = false; // this is to avoid double refresh attempts because of things like useEffect being called twice before state updates in react 18 strict mode
  guesting: boolean = false; // this is to avoid double guest login attempts because of things like useEffect being called twice before state updates in react 18 strict mode
  loggingOut: boolean = false; // this is to avoid double log-out attempts because of things like useEffect being called twice before state updates in react 18 strict mode
  sendingResult: boolean = false; // this is to avoid double sendResult attempts because of things like useEffect being called twice before state updates in react 18 strict mode
  exchangingCode: boolean = false;
  get busy(): boolean {
    return (
      this.refreshing ||
      this.guesting ||
      this.loggingOut ||
      this.sendingResult ||
      this.exchangingCode
    );
  }
  /**
   * Create a new AuthClient - generally you only need one instance per website
   * @param isNewTab set to true if this client is being opened in a new tab/window that was (probably) spawned by this client
   */
  constructor(
    isNewTab: boolean = false,
    env: CognitoEnv,
    guestReferenceIdGenerator?: () => string
  ) {
    this.logger = new Logger(`[BBAuthClient]`);
    this.logger.debug(
      `constructor] new auth client - new tab mode? ${isNewTab}`
    );
    this.env = env;
    this.postMessageClient = new BBPostMessageClient(
      this.env.AOB_ENV as EnvironmentModes,
      null,
      null,
      isNewTab
    );
    this.awsClient = new CognitoIdentityProviderClient({
      region: "us-east-1",
    });
    this.guestReferenceIdGenerator = guestReferenceIdGenerator;
  }

  unregister(): void {
    if (!this.busy) {
      this.logger.info(`.unregister]`);
      this.postMessageClient.unregister();
    } else {
      this.logger.warn(`.unregister] unrgister called while busy... ignoring`);
    }
  }

  private async fetchUAM<P = any>({
    method,
    path,
    headers = {},
    body,
    includeCookies = false,
  }: FetchOptions): Promise<{
    metadata: { code: string; message: string };
    payload: P;
  }> {
    const credentials = includeCookies ? "include" : undefined;
    // try {
    const response = await fetch(
      `${this.env.UAM_BASE}${path[0] !== "/" ? "/" : ""}${path}`,
      {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        credentials, // https://stackoverflow.com/a/74951122/2453932
        body: JSON.stringify(body),
      }
    );
    // }
    return response.json();
  }

  async logout(): Promise<void> {
    if (this.loggingOut) {
      throw new BBAuthClientBusyError("Already logging-out");
    }
    this.logger.info(`.logout]`);
    this.loggingOut = true;
    this.clearStoredAccessToken();
    await this.fetchUAM({
      path: "bb-auth-frame/logout",
      method: "post",
      includeCookies: true,
      body: {
        clientId: this.env.COG_CLIENT_ID,
      },
    });
    this.loggingOut = false;
  }

  doAuthPhaseCallback(
    phase: AuthPhase,
    callback: AuthPhaseCallback | undefined
  ) {
    if (callback !== undefined) {
      callback(phase, AuthPhaseMessages[phase]);
    }
  }

  async loginWithCognito(
    userName: string,
    password: string,
    shouldRegisterResult: boolean,
    isGuest: boolean,
    authPhaseCallback?: AuthPhaseCallback
  ): Promise<LoginResult> {
    this.logger.info(
      `.loginWithCognito] ${userName} - shouldRegister?: ${shouldRegisterResult} isGuest?:${isGuest}`
    );
    this.doAuthPhaseCallback(
      AuthPhase.VERIFYING_CREDENTIALS,
      authPhaseCallback
    );
    const response = await fetch(this.env.COG_URL, {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      },
      mode: "cors",
      cache: "no-cache",
      method: "POST",
      body: JSON.stringify({
        ClientId: this.env.COG_CLIENT_ID,
        AuthFlow: "USER_PASSWORD_AUTH",
        AuthParameters: {
          PASSWORD: password,
          USERNAME: userName,
        },
      }),
    });
    const body = await response.json();
    if (!body.AuthenticationResult) {
      return {
        successful: false,
        message: "Unable to log in. Please check your username or password.",
        IdToken: null,
        AccessToken: null,
        RefreshToken: null,
        isGuest: null,
      };
    } else {
      const { IdToken, AccessToken, RefreshToken } =
        body.AuthenticationResult as CognitoAuthResult;
      const result: SuccessfullLoginResult = {
        successful: true,
        message: null,
        IdToken,
        AccessToken,
        RefreshToken,
        isGuest,
      };
      if (shouldRegisterResult) {
        await this.registerAuthResult(result, {
          isGuest,
          authPhaseCallback,
        });
      }
      return result;
    }
  }

  private getRedirectUri(): string {
    return `${this.env.BB_AUTH_DOMAIN}/login-redirect`;
  }

  codeAlreadyConumed(code: string): boolean {
    return code in this.consumedAuthCodes;
  }

  async codeForTokens(code: string): Promise<LoginResult> {
    if (code in this.consumedAuthCodes) {
      throw new Error(
        this.logger.logMsg(`codeForTokens] code already consumed`)
      );
    }
    this.logger.info(`.codForTokens]`);
    this.exchangingCode = true; // this is being set to avoid an early .unregister

    this.consumedAuthCodes[code] = true;
    const result = await fetch(`${this.env.COG_DOMAIN}/oauth2/token`, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams({
        grant_type: "authorization_code",
        client_id: this.env.COG_CLIENT_ID,
        redirect_uri: this.getRedirectUri(),
        code: code,
      }).toString(),
    });
    const json = await result.json();
    if (result.status === 200) {
      this.logger.debug(`[.codeForTokens] got tokens`);
      return {
        successful: true,
        message: null,
        IdToken: json.id_token,
        AccessToken: json.access_token,
        RefreshToken: json.refresh_token,
        isGuest: false,
      };
    } else {
      const message: string =
        json.error === "invalid_grant"
          ? "We can't verify your identity at this time. Did you use the wrong 3rd party email by chance?"
          : "Something went wrong on our end while we were trying to verify your account. Please try again or contact customer support.";
      this.logger.error(
        `.codeForTokens] Got invalid_grant from cognito for code ${code}`
      );
      return {
        successful: false,
        message,
        IdToken: null,
        AccessToken: null,
        RefreshToken: null,
        isGuest: null,
      };
    }
  }

  async loginWithSocial(
    network: SocialSignInNetwork,
    authPhaseCallback?: AuthPhaseCallback
  ): Promise<LoginResult> {
    this.logger.info(`.loginWithSocial] ${network}`);
    // https://<your_user_pool_domain>/login?response_type=code&client_id=<your_client_id>&redirect_uri=https://www.example.com
    const url = `${
      this.env.COG_DOMAIN
    }/oauth2/authorize?response_type=code&client_id=${
      this.env.COG_CLIENT_ID
    }&redirect_uri=${this.getRedirectUri()}&identity_provider=${network}`;
    this.logger.debug(`.loginWithGoogle] opening new window at...`);
    this.logger.debug(url);
    // TODO - new window popop styling
    const tabWindow = window.open(url);
    if (tabWindow === null) {
      throw new Error(
        this.logger.logMsg(".loginWithSocial ]new tab window is null!!")
      );
    }
    const tabMessageClient = new BBPostMessageClient(
      this.env.AOB_ENV as EnvironmentModes,
      tabWindow,
      this.env.BB_AUTH_DOMAIN,
      true
    );

    this.logger.debug(` Waiting for auth result from new tab`);
    const loginResult = await tabMessageClient.waitFor<LoginResult>(
      MessageTypes.AUTH_RESULT
    );
    this.logger.debug(` got result back. Success:${loginResult.successful}`);
    tabMessageClient.unregister();

    if (loginResult.successful) {
      await this.registerAuthResult(loginResult, {
        isGuest: false,
        authPhaseCallback,
      });
      await this.transferGuest(loginResult.AccessToken, authPhaseCallback);
    } else {
      this.logger.info(`.loginWithSocial] social login failed`);
    }

    return loginResult;
  }

  setStoredAccessToken(
    token: string,
    expiresAt: Date | number,
    isGuest: boolean
  ): void {
    localStorage.setItem(STORED_TOKEN_ID, token);
    let expiresAtTimestamp =
      typeof expiresAt === "number" ? expiresAt : expiresAt.getTime();
    this.logger.debug(
      `.setStoredAccessToken] guest?:${isGuest}, expires at ${new Date(
        expiresAtTimestamp
      ).toISOString()}`
    );
    localStorage.setItem(
      STORED_TOKEN_EXPIRES_AT_ID,
      expiresAtTimestamp.toString(10)
    );
    if (isGuest) {
      localStorage.setItem(STORED_TOKEN_IS_GUEST, "true");
    } else {
      localStorage.removeItem(STORED_TOKEN_IS_GUEST);
    }
  }

  getStoredAccessToken(): {
    accessToken: string;
    expiresAt: Date;
    expired: boolean;
    isGuest: boolean;
  } | null {
    const token = localStorage.getItem(STORED_TOKEN_ID);
    const isGuest = localStorage.getItem(STORED_TOKEN_IS_GUEST) === "true";
    const expiresAtString = localStorage.getItem(STORED_TOKEN_EXPIRES_AT_ID);
    const expiresAtTimestamp = parseInt(expiresAtString || "", 10);
    if (token === null || isNaN(expiresAtTimestamp)) {
      this.logger.debug(`.getStoredAccessToken] nothing stored`);
      return null;
    } else {
      const expired = Date.now() >= expiresAtTimestamp;
      this.logger.debug(
        `.getStoredAccessToken] expired?:${expired} isGuest?:${isGuest}`
      );
      return {
        accessToken: token,
        expiresAt: new Date(expiresAtTimestamp),
        expired,
        isGuest,
      };
    }
  }
  clearStoredAccessToken(): void {
    this.logger.debug(`.clearStoredAccessToken]`);
    localStorage.removeItem(STORED_TOKEN_ID);
    localStorage.removeItem(STORED_TOKEN_EXPIRES_AT_ID);
    localStorage.removeItem(STORED_TOKEN_IS_GUEST);
  }

  /*
   * This method turned out to not be needed - if you have an accessToken, in theory, you must be confirmed
   * leaving commented out here in case that turns out to not be the case
   */
  // async isEmailConfirmed(accessToken:string):Promise<boolean> {
  //   try {
  //     const command = new GetUserCommand({
  //       AccessToken: accessToken
  //     })
  //     const {UserAttributes} = await this.awsClient.send(command)
  //     if (UserAttributes === undefined) {
  //       this.logger.warn(`.isEmailConfirmed] got undefined user attribute array back`)
  //       return false
  //     }

  //     const verifiedAtt = UserAttributes.find(attribute => attribute.Name === "email_verified")
  //     if (verifiedAtt === undefined) {
  //       this.logger.warn(`.isEmailConfirmed] could not find email_verified attribute in GetUser result`)
  //       return false
  //     }

  //     this.logger.debug(`.isEmailConfirmed] email_verified = ${verifiedAtt.Value} - ${verifiedAtt.Value === "true"}`)
  //     return verifiedAtt.Value === "true"
  //   }
  //   catch(e:any) {
  //     this.logger.error(`.isEmailConfirmed] Got error while checking email confirmation status: ${e.message}`)
  //     this.logger.error(e)
  //     return false
  //   }
  // }

  async registerAuthResult(
    authResult: SuccessfullLoginResult,
    options: {
      isGuest?: boolean;
      authPhaseCallback: AuthPhaseCallback | undefined;
    }
  ): Promise<void> {
    const { isGuest = false, authPhaseCallback } = options;
    this.logger.info(
      `.registerAuthResult] Attempting to register token isGuest?: ${isGuest}`
    );
    this.doAuthPhaseCallback(AuthPhase.REGISTERING_TOKENS, authPhaseCallback);
    try {
      // TODO CT: Better/any error handling here
      await this.fetchUAM({
        method: "post",
        path: "bb-auth-frame/register",
        includeCookies: true,
        body: {
          accessToken: authResult.AccessToken,
          refreshToken: authResult.RefreshToken,
        },
      });
      this.setStoredAccessToken(
        authResult.AccessToken,
        Date.now() + this.env.ACCESS_TIMEOUT_MILLISECONDS,
        isGuest
      );
      this.logger.debug(
        `.registerAuthResult] Successfully register refresh token.`
      );
      // if (transferGuestToThisAccount) {
      //   if (!isGuest) {
      //     this.logger.debug(`.registerAuthResult] Attempting to transfer guest user bbdocs, if exists`)
      //     await this.transferGuest(authResult.AccessToken, authPhaseCallback)
      //   }
      // }
    } catch (e) {
      this.logger.error(` Could not register credentials with platform!!!`);
      throw e;
    }
  }

  async sendAuthResult(authResult: LoginResult): Promise<void> {
    if (this.sendingResult) {
      this.logger.debug(`.sendAuthResult] already sending/busy - ignoring`);
      throw new BBAuthClientBusyError();
    }
    this.sendingResult = true;

    try {
      this.logger.debug(
        ` Attempting to send auth result to parent. Child client ${this.postMessageClient.instanceLabel}`
      );
      await this.postMessageClient.sendMessage<LoginResult>({
        messageType: MessageTypes.AUTH_RESULT,
        payload: authResult,
      });
    } catch (e) {
      this.logger.error(` Could not send result to parent frame!!!`);
      throw e;
    } finally {
      this.sendingResult = false;
    }
  }

  async refreshAccessToken(): Promise<LoginResult> {
    const refreshResponse = await this.fetchUAM<{ accessToken: string }>({
      method: "post",
      path: "bb-auth-frame/refresh",
      includeCookies: true,
      body: {
        clientId: this.env.COG_CLIENT_ID,
      },
    });

    if (refreshResponse.payload.accessToken !== null) {
      const existingStored = this.getStoredAccessToken();
      const isGuest = existingStored?.isGuest === true ? true : false;
      this.setStoredAccessToken(
        refreshResponse.payload.accessToken,
        (Date.now() + this.env.ACCESS_TIMEOUT_MILLISECONDS) as number,
        isGuest
      );
      return {
        successful: true,
        message: null,
        AccessToken: refreshResponse.payload.accessToken,
        RefreshToken: null,
        IdToken: null,
        isGuest,
      };
    } else {
      this.logger.debug(
        `.attemptRefresh] unsuccessful refresh attempt ${refreshResponse.metadata.message}`
      );
      this.clearStoredAccessToken(); // it doesn't work - so clear it
      return {
        successful: false,
        message: "Your log-in credentials have expired. Please log in again.",
        AccessToken: null,
        IdToken: null,
        RefreshToken: null,
        isGuest: null,
      };
    }
  }

  /**
   * First looks for an non-expired AccessToken in localStored
   * if found and expired - attempts to use refresh token in httpOnly cookie (set by registerAuthResult)
   * if found and not expired - returned in LoginResult
   * otherwise, return unsuccessful login result
   * @returns a LoginResult representing the outcome of the silent auth attempt
   */
  async trySilentAuth(guestMode: boolean): Promise<LoginResult> {
    // this isn't exactly a semaphore, but it will work in JS land
    if (this.refreshing) {
      throw new BBAuthClientBusyError();
    }
    this.refreshing = true;

    this.logger.info(`.trySilentAuth] guestMode?:${guestMode}`);

    const storedToken = this.getStoredAccessToken();
    if (storedToken === null) {
      this.refreshing = false;
      return {
        successful: false,
        message: "Not currently logged in.",
        AccessToken: null,
        IdToken: null,
        RefreshToken: null,
        isGuest: null,
      };
    } else if (storedToken.isGuest === guestMode) {
      if (storedToken.expired) {
        const result = await this.refreshAccessToken();
        this.refreshing = false; // wait until asynch is done, then unset flag
        return result;
      } else {
        this.refreshing = false;
        return {
          successful: true,
          message: null,
          AccessToken: storedToken.accessToken,
          IdToken: null,
          RefreshToken: null,
          isGuest: guestMode,
        };
      }
    } else {
      // otherwise - we're trying to silent auth as a full user when the existing token is for a guest user, or vice versa
      this.logger.debug(
        `.trySilentAuth] stored token type doesnt match current guest/not-guest mode. stored token is guest: ${storedToken.isGuest}, in guest mode?: ${guestMode}. Logging out`
      );
      await this.logout();
      return {
        successful: false,
        message: "Not currently logged in.",
        AccessToken: null,
        IdToken: null,
        RefreshToken: null,
        isGuest: null,
      };
    }
  }

  /* ***************************************************************************
   * Guest Authentication methods
   *************************************************************************** */

  getStoredGuestReferenceId(): string | null {
    return localStorage.getItem(STORED_GUEST_REFERENCE_ID);
  }
  setStoredGuestReferenceId(referenceId: string): void {
    localStorage.setItem(STORED_GUEST_REFERENCE_ID, referenceId);
  }
  clearStoredGuestReferenceId(): void {
    localStorage.removeItem(STORED_GUEST_REFERENCE_ID);
  }

  async authenticateAsGuest(
    shouldRegisterResult: boolean = true,
    shouldTryFullUserSilentAuthFirst: boolean = true
  ): Promise<LoginResult> {
    // this isn't exactly a semaphore, but it will work in JS land
    if (this.guesting) {
      throw new BBAuthClientBusyError();
    }
    this.guesting = true;

    const logger = this.logger.methodLogger(`.authenticateAsGuest]`);
    logger.info(
      `shouldRegister?:${shouldRegisterResult} shouldTryFullUserSilentAuth first?:${shouldTryFullUserSilentAuthFirst}`
    );

    if (shouldTryFullUserSilentAuthFirst) {
      logger.debug(`trying silent auth for non-guests first`);
      const silentResult = await this.trySilentAuth(false);
      if (silentResult.successful) {
        logger.debug(`non-guest silent auth was successful - returning tokens`);
        return silentResult;
      } else {
        logger.debug(
          `non-guest silent auth was NOT successful - moving on to guest auth`
        );
      }
    }

    let guestRefId = this.getStoredGuestReferenceId();
    if (guestRefId === null) {
      if (this.guestReferenceIdGenerator !== undefined) {
        guestRefId = this.guestReferenceIdGenerator();
        this.setStoredGuestReferenceId(guestRefId);
        logger.debug(
          ` no existing guest reference ID found in storage - will create a new id ${guestRefId}`
        );
      } else {
        throw new Error(
          logger.logMsg(
            `no reference ID generator was passed into constructor - can't create a new guest!!!`
          )
        );
      }
    } else {
      logger.debug(` found existing guest ref ID`);
    }
    let payload;
    try {
      const result = await this.fetchUAM<{
        username: string;
        guestPassword: string;
      }>({
        method: "post",
        path: "guest/find",
        body: {
          referenceId: guestRefId,
        },
      });
      payload = result.payload;
    } catch (e) {
      this.logger.error(
        `.authenticateAsGuest] got error while looking up guest info with guest ref ID: ${guestRefId}. MESSAGE: ${
          (e as any)?.message
        }`
      );
      this.guesting = false;
      return {
        successful: false,
        message:
          "We're having issues logging you in as a guest. Please try again later.",
        AccessToken: null,
        IdToken: null,
        RefreshToken: null,
        isGuest: null,
      };
    }
    const result = await this.loginWithCognito(
      payload.username,
      payload.guestPassword,
      shouldRegisterResult,
      true
    );
    if (result.successful) {
      logger.debug(` successfully logged in as guest user ${guestRefId}`);
    }
    this.guesting = false;
    return result;
  }

  /* ***************************************************************************
   * Account creation / sign up
   *************************************************************************** */
  async signup(
    name: string,
    email: string,
    userName: string,
    password: string
  ): Promise<void> {
    const logger = this.logger.methodLogger("signup");

    /*
     * create the account with the cognito REST API
     */
    logger.info(`Attemping to create user ${name}, ${email}, ${userName}`);
    try {
      const command = new SignUpCommand({
        ClientId: this.env.COG_CLIENT_ID,
        Username: userName,
        Password: password,
        UserAttributes: [
          {
            Name: "name",
            Value: name,
          },
          {
            Name: "email",
            Value: email,
          },
        ],
      });
      await this.awsClient.send(command);
      logger.debug(`Done creating cognito user`);
    } catch (e: any) {
      logger.error(`.signup] error: ${e.message}`);
      throw e;
    }
  }

  async confirm(
    username: string,
    password: string,
    confirmationCode: string | null,
    authPhaseCallback?: AuthPhaseCallback
  ): Promise<LoginResult> {
    const logger = this.logger.methodLogger("confirm");
    logger.debug(`username ${username}`);
    this.doAuthPhaseCallback(AuthPhase.CONFIRMING_ACCOUNT, authPhaseCallback);

    if (confirmationCode !== null) {
      logger.debug(
        `Attempting to confirm user ${username} with conf code ${confirmationCode}`
      );
      const confirmCommand = new ConfirmSignUpCommand({
        ClientId: this.env.COG_CLIENT_ID,
        ConfirmationCode: confirmationCode,
        Username: username,
      });

      await this.awsClient.send(confirmCommand);
      logger.debug(`Done confirming user.`);
    }

    /*
     * login with the new account (not confirmed yet, probably)
     */
    logger.debug(`Clearing any existing tokens`);
    await this.logout();
    logger.debug(`Attempting to log in with new credentials`);
    const tokens = await this.loginWithCognito(username, password, true, false);
    if (!tokens.successful) {
      logger.error("Could not log user in - not confirmed yet, probably");
      throw new BBAuthUserNotConfirmedError(
        `Could not log-in for user during confirmation`
      );
    }
    logger.debug(`logged in with new credentials`);

    /*
     * create a Buildbox account
     */
    logger.debug(
      `Attempting to log into platform with new creds. username: ${username}`
    );
    await this.fetchUAM({
      path: "/webclientlogin",
      method: "post",
      headers: {
        Token: tokens.AccessToken,
      },
    });
    logger.debug(`logged into bb platform`);

    logger.debug(`Attempting to transfer guest account, if exists`);
    await this.transferGuest(tokens.AccessToken, authPhaseCallback);

    logger.debug(`Signup done - returning tokens`);
    // if here, we're done for now - still need to confirm account
    return tokens;
  }

  /**
   * move a guests BBDocs to a "real" account
   * associate the guest user with the "real" user
   * delete the guest ref ID from localStorage
   */
  async transferGuest(
    userAccessToken: string,
    authPhaseCallback: AuthPhaseCallback | undefined
  ): Promise<{ guestUserId: string; newUserId: string } | null> {
    const guestRefId = this.getStoredGuestReferenceId();
    if (guestRefId !== null) {
      this.doAuthPhaseCallback(
        AuthPhase.TRANSFERING_GUEST_USER,
        authPhaseCallback
      );
      this.logger.debug(
        `.transferGuest] found guest credentials. Will attempt to tranfer to new user.`
      );
      const guestTokens = await this.authenticateAsGuest(false, false);
      const result = (
        await this.fetchUAM<{ guestUserId: string; newUserId: string }>({
          path: "guest/transfer",
          method: "post",
          headers: {
            Token: userAccessToken,
          },
          body: {
            referenceId: guestRefId,
            accessToken: guestTokens.AccessToken,
          },
        })
      ).payload;
      this.logger.debug(
        `.transferGuest] successfully transferred guest account. Deleting guest reference ID from local storage.`
      );
      this.clearStoredGuestReferenceId();
      return result;
    } else {
      this.logger.debug(`.transferGuest] no guest ref ID - not transferring`);
      return null;
    }
  }
}
