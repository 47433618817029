import { ReactElement } from "react";
import classNames from "classnames"

import styles from "./ValidationMessage.module.css"

export interface Props {
  message?: ReactElement|string|null
  className?: string
}

export function ValidationMessage({message=null, className}:Props):ReactElement|null {
  if (message === null) {
    return null
  }

  return (
    <div className={classNames(styles.container, className)}>
      {message}
    </div>
  )
}
