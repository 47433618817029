import { ReactElement, useState } from "react";
import { Input } from "../../Components/Input";

import { Button } from "../../Components/Button";
import { Container } from "../../Components/Container";
import { SocialSignInNetwork } from "../../BBAuthClient";
import { AppleButton, FacebookButton, GoogleButton } from "./SocialButtons";
import { Divider } from "../../Components/Divider";
import { ConditionalRender } from "../../Components/ConditionalRender";

import styles from "./Login.module.css"
import { StyleType } from "../../Components/ComponentTypes";
import { ValidationMessage } from "../../Components/ValidationMessage";

// https://stackoverflow.com/a/201378/2453932
/* eslint-disable no-control-regex */
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

function validateName(name:string|undefined):string|null {
  if (name === undefined || name.trim() === "") {
    return "Please supply your name."
  }
  else {
    return null
  }
}

function validateEmail(email:string|undefined):string|null {
  if (email === undefined || email.trim() === "") {
    return "Please supply an email."
  }
  else if (!EMAIL_REGEX.test(email)) {
    return "The email you provided is not a valid email address."
  }
  else {
    return null
  }
}

function validateUsername(username:string|undefined):string|null {
  if (username === undefined || username.trim() === "") {
    return "Please supply a username."
  }
  else {
    return null
  }
}

function validatePassword(password:string|undefined):string|null {
  if (password === undefined || password.trim() === "") {
    return "Please supply a password."
  }
  else {
    return null
  }
}

function validatePasswordCheck(password:string|undefined, passwordCheck:string|undefined):string|null {
  if (password !== passwordCheck) {
    return "Your passwords must match."
  }
  else {
    return null
  }
}

export type Props = {
  startInCreateMode?:boolean
  compact?:boolean
  onLogin?:(username:string,password:string)=>void
  onCreateAccount?:(name:string, email:string, username:string, password:string)=>void
  onSocialClick?:(network:SocialSignInNetwork)=>void
}
export function Login({startInCreateMode=false, compact=false, onLogin: onSubmit, onCreateAccount, onSocialClick}:Props):ReactElement {
  const [createMode, setCreateMode] = useState<boolean>(startInCreateMode)

  // uncomment this for easy form filling if your doing account creation testing
  // const debugMonthDictionary= ["JAN","FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
  // const debugUserName = `test-user-${debugMonthDictionary[(new Date()).getMonth()]}${(new Date()).getDate()}-${Math.floor(Math.random()*10000)}`
  // const [name, setName] = useState<string|undefined>(debugUserName)
  // const [email, setEmail] = useState<string|undefined>(`monitorswet@wculturey.com`) // from https://generator.email
  // const [username, setUsername] = useState<string|undefined>(debugUserName+"-username")
  // const [password, setPassword] = useState<string|undefined>("fakefake123")
  // const [passwordCheck, setPasswordCheck] = useState<string|undefined>("fakefake123")

  const [name, setName] = useState<string|undefined>(undefined)
  const [email, setEmail] = useState<string|undefined>(undefined)
  const [username, setUsername] = useState<string|undefined>(undefined)
  const [password, setPassword] = useState<string|undefined>(undefined)
  const [passwordCheck, setPasswordCheck] = useState<string|undefined>(undefined)

  const [nameErrMessage, setNameErrMessage] = useState<null|string>(null)
  const [emailErrMessage, setEmailErrMessage] = useState<null|string>(null)
  const [usernameErrMessage, setUsernameErrMessage] = useState<null|string>(null)
  const [passwordErrMessage, setPasswordErrMessage] = useState<null|string>(null)
  const [passwordCheckErrMessage, setPasswordCheckErrMessage] = useState<null|string>(null)

  const nameInvalid = nameErrMessage !== null
  const emailInvalid = emailErrMessage !== null
  const usernameInvalid = usernameErrMessage !== null
  const passwordInvalid = passwordErrMessage !== null
  const passwordCheckInvalid = passwordCheckErrMessage !== null

  function handleSocialClick(network:SocialSignInNetwork):void {
    if (onSocialClick !== undefined) {
      onSocialClick(network)
    }
  }
  function handleCreateAccountManuallyClick():void {
    setCreateMode(!createMode)
  }
  function handleLoginClick():void {
    const usernameErrMessage = validateUsername(username)
    setUsernameErrMessage(usernameErrMessage)
    const passwordErrMessage = validatePassword(password)
    setPasswordErrMessage(passwordErrMessage)
    if (usernameErrMessage === null && passwordErrMessage === null && onSubmit !== undefined) {
      // AKA username and password are valid and we have a valid submit listener
      onSubmit(username as string,password as string)
    }
  }
  function handleCreateAccountClick():void {
    const nameErrMessage = validateName(name)
    setNameErrMessage(nameErrMessage)
    const emailErrMessage = validateEmail(email)
    setEmailErrMessage(emailErrMessage)
    const usernameErrMessage = validateUsername(username)
    setUsernameErrMessage(usernameErrMessage)
    const passwordErrMessage = validatePassword(password)
    setPasswordErrMessage(passwordErrMessage)
    const passwordCheckErrMessage = validatePasswordCheck(password, passwordCheck)
    setPasswordCheckErrMessage(passwordCheckErrMessage)

    const allCreateFieldsValid = 
      nameErrMessage === null &&
      emailErrMessage === null &&
      usernameErrMessage === null &&
      passwordErrMessage === null &&
      passwordCheckErrMessage === null

    if (onCreateAccount !== undefined && allCreateFieldsValid) {
      onCreateAccount(name as string, email as string, username as string, password as string)
    }
  }

  return (
    <Container logo={true}>
      <form>

        {/* Log In */}
        <ConditionalRender condition={!createMode}>
          <h3>Sign in to your Buildbox Account</h3>
          <GoogleButton type={StyleType.flashy} rounded onClick={handleSocialClick} />
          <FacebookButton type={StyleType.flashy} rounded onClick={handleSocialClick} />
          <AppleButton type={StyleType.flashy} rounded onClick={handleSocialClick} />
          <Button block rounded onClick={handleCreateAccountManuallyClick}>Create an Account</Button>
          <Divider>or</Divider>
          <p>Sign in with your Buildbox username and password</p>
          <ValidationMessage message={usernameErrMessage} />
          <Input onChange={setUsername} validationIssue={usernameInvalid} placeholder="username" value={username} htmlAutocompleteAttribute={"username"} />
          <ValidationMessage message={passwordErrMessage} />
          <Input onChange={setPassword} validationIssue={passwordInvalid} placeholder="password" isPassword value={password} htmlAutocompleteAttribute={"current-password"} />
          <Button block rounded onClick={handleLoginClick}>Log In</Button>
        </ConditionalRender>

        {/* Create Account */}
        <ConditionalRender condition={createMode}>
          <h3>Get started with Buildbox!</h3>
          <GoogleButton text="Sign up with Google" type={StyleType.flashy} rounded onClick={handleSocialClick} />
          <FacebookButton text="Sign up with Facebook" type={StyleType.flashy} rounded onClick={handleSocialClick} />
          <AppleButton text="Sign up with Apple" type={StyleType.flashy} rounded onClick={handleSocialClick} />
          <Button block rounded onClick={handleCreateAccountManuallyClick}>Sign in to an Existing Account</Button>

          <ConditionalRender condition={!compact}>
            <Divider>or</Divider>
            <p>Please fill out the following form to create your new Buildbox account.</p>
            <ValidationMessage message={usernameErrMessage} />
            <Input onChange={setUsername} validationIssue={usernameInvalid} placeholder="username" value={username} htmlAutocompleteAttribute={"username"} />
            <ValidationMessage message={emailErrMessage} />
            <Input onChange={setEmail} validationIssue={emailInvalid} placeholder="email" value={email} htmlAutocompleteAttribute={"email"} />
            <ValidationMessage message={nameErrMessage} />
            <Input onChange={setName} validationIssue={nameInvalid} placeholder="your name" value={name} htmlAutocompleteAttribute={"name"} />

            <ValidationMessage message={passwordErrMessage} />
            <Input onChange={setPassword} validationIssue={passwordInvalid} placeholder="password" isPassword value={password} htmlAutocompleteAttribute={"new-password"} />
            <ValidationMessage message={passwordCheckErrMessage} />
            <Input onChange={setPasswordCheck} validationIssue={passwordCheckInvalid} placeholder="retype password" isPassword value={passwordCheck} htmlAutocompleteAttribute={"new-password"} />
            <Button rounded block onClick={handleCreateAccountClick}>Create an Account</Button>
          </ConditionalRender>
          <p className={styles.disclaimers}>
            By creating an account, I agree to all Buildbox&nbsp;
            <a target="_blank" rel="noreferrer" href="https://www.buildbox.com/terms/">Terms of Service</a>,&nbsp;
            <a target="_blank" rel="noreferrer" href="https://www.buildbox.com/privacy-policy/">Privacy Policy</a>,
            and <a target="_blank" rel="noreferrer" href="https://www.buildbox.com/eula/">EULA Agreements</a>.
          </p>
        </ConditionalRender>
      </form>
    </Container>
  )
}