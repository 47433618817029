import { ChangeEvent, ReactElement } from "react";

import styles from "./Input.module.css"
import classnames from "classnames";

type Props = {
  className?: string,
  validationIssue?:boolean
  label?: ReactElement
  placeholder?:string
  value?:string
  isPassword?:boolean
  hidden?: boolean

  /**
   * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute
   * i.e. "current-password"|"new-password"|"username"
   */
  htmlAutocompleteAttribute?:string

  onChange?:(newValue:string)=>void
}

export function Input(props:Props):ReactElement|null {
  const {hidden=false, className, validationIssue=false, label, placeholder, value, isPassword=false, htmlAutocompleteAttribute, onChange} = props
  const inputType = isPassword? "password" : "text"

  if (hidden) {
    return null
  }

  function handleOnChange(event:ChangeEvent<HTMLInputElement>):void {
    if (onChange !== undefined) {
      onChange(event.target.value)
    }
  }

  return (
    <div className={classnames(className, styles.container, {[styles.validationIssue]:validationIssue!==false})}>
      {label}
      <input
        type={inputType}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={value !== undefined? value : ""}
        autoComplete={htmlAutocompleteAttribute}
      />
    </div>
  )
}