import React, { ReactElement, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import {v4 as uuid} from "uuid"

import { Env } from './Env';
import { LoginFlow } from './Pages/LoginFlow';
import { BBAuthClient } from './BBAuthClient';
import { Route, SimpleRouter } from './Components/SimpleRouter';
import { LoginRedirectFlow } from './Pages/LoginRedirectFlow';
import { GuestFlow } from './Pages/GuestFlow';
import { LogoutFlow } from './Pages/LogoutFlow';

import './index.css';
import { RefreshFlow } from './Pages/RefreshFlow';

let moduleInMemoryAuthClient:BBAuthClient|null = null

export function SiteIndex(): ReactElement {
  // const [currentPath, setCurrentPath] = useState<string>(window.location.pathname)
  const currentPath = window.location.pathname

const isNewTab = currentPath.endsWith("-redirect")
  const authClient = useMemo(() => {
    if (moduleInMemoryAuthClient === null) {
      console.info(`[bbAuthFrame - SiteIndex] creating new BBAuthClient`)
      moduleInMemoryAuthClient = new BBAuthClient(isNewTab, Env, ()=>uuid())
      if (Env.AOB_ENV.toLowerCase() !== "prod") {
        ;(window as any).bbAuthClient = moduleInMemoryAuthClient;
      }
    }
    return moduleInMemoryAuthClient
  },[isNewTab])
  ;((window as any).bbAuthClient = authClient); // makes the authClient accessible from the console

  const compact = window.location.search.indexOf("compact=true") >= 0

  return (
    // <SimpleRouter path={currentPath}>
    <SimpleRouter>
      <Route path="/">
        <LoginFlow authClient={authClient} compact={compact} />
      </Route>
      <Route path="/create-account">
        <LoginFlow authClient={authClient} startInCreateMode compact={compact} />
      </Route>
      <Route path="/guest-login">
        <GuestFlow authClient={authClient} />
      </Route>
      <Route path="/login-redirect">
        <LoginRedirectFlow authClient={authClient} />
      </Route>
      <Route path="/logout-redirect">
        <LogoutFlow authClient={authClient} />
      </Route>
      <Route path="/refresh">
        <RefreshFlow />
      </Route>
      <Route path="/refresh-guest">
        <RefreshFlow guestMode />
      </Route>
    </SimpleRouter>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <SiteIndex />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
