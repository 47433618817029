import classNames from "classnames";
import { ReactElement } from "react";

import styles from "./Divider.module.css"

export interface Props {
  hidden?: boolean
  children?: ReactElement|ReactElement[]|string
  className?: string
  align?:"left"|"center"|"right"
}

export function Divider({hidden=false,children,className,align="center"}:Props):ReactElement|null {
  if (hidden) {
    return null
  }
  return (
    <div className={classNames(styles.divider, styles[align], className)}><div className={styles.content}>{children}</div></div>
  )
}
