import { ReactElement } from "react";
import { Container } from "../../Components/Container";
import { Ellipsis } from "../../Components/Ellipsis";

export function RefreshFlow(props:{guestMode?:boolean, delay?:number}): ReactElement {
  const {guestMode=false, delay=1} = props

  const path = guestMode? "/guest-login" : "/"

  setTimeout(()=>{
    console.log(`[RefreshFlow] redirecting to ${path} after ${delay}ms`)
    window.location.href = path
  }, delay)

  return (
    <Container>
      Redirecting <Ellipsis />
    </Container>
  )
}
