import { ReactElement } from "react"
import classnames from "classnames"

import styles from "./Login.module.css"
import { SocialSignInNetwork } from "../../BBAuthClient"
import { Button } from "../../Components/Button"
import { StyleType } from "../../Components/ComponentTypes"

export interface SocialButtonProps<T extends SocialSignInNetwork> {
  type?:StyleType
  rounded?:boolean,
  imageSrc:string
  imageSrcWhenRounded?:string
  text:string
  onClick: (network:T)=>void|(()=>void)

  hidden?: boolean
  className?:string|((string|undefined)[])
}

export type SpecificSocialButtonProps<T extends SocialSignInNetwork> = Omit<SocialButtonProps<T>, "imageSrc"|"text"|"className"> & {
  imageSrc?:string
  imageSrcWhenRounded?:string
  text?:string
  className?:string
}

export function SocialButton<T extends SocialSignInNetwork = SocialSignInNetwork>({network,type,rounded,imageSrc,imageSrcWhenRounded=imageSrc,text,onClick,className,hidden=false}:SocialButtonProps<T>&{network:T}):ReactElement|null {
  if (hidden) {
    return null
  }

  let finalImageSrc:string = imageSrc
  if (rounded) {
    finalImageSrc = imageSrcWhenRounded
  }

  return (
    <Button type={type} rounded={rounded} className={classnames(styles.socialButton, {[styles.rounded]:rounded}, className)} onClick={() => onClick(network)}>
      <img src={finalImageSrc} alt={text} />
      <span>{text}</span>
    </Button>
  )
}

export function GoogleButton({
  imageSrc = "./btn_google_dark_normal_ios.svg",
  imageSrcWhenRounded = "https://frontend-assets.buildbox.com/Icon_Google_white.svg",
  text = "Sign in with Google",
  className,
  ...otherProps
}: SpecificSocialButtonProps<SocialSignInNetwork.Google>): ReactElement {
  return (
    <SocialButton
      {...otherProps}
      className={[styles.googleButton, className]}
      network={SocialSignInNetwork.Google}
      imageSrc={imageSrc}
      imageSrcWhenRounded={imageSrcWhenRounded}
      text={text}
    />
  )
}

export function AppleButton({
  imageSrc = "./sign_in_with_apple.svg",
  imageSrcWhenRounded = "https://frontend-assets.buildbox.com/Apple_logo_white.svg",
  text = "Sign in with Apple",
  className,
  ...otherProps
}: SpecificSocialButtonProps<SocialSignInNetwork.SignInWithApple>): ReactElement {

  return (
    <SocialButton
      {...otherProps}
      className={[styles.appleButton, className]}
      network={SocialSignInNetwork.SignInWithApple}
      imageSrc={imageSrc}
      imageSrcWhenRounded={imageSrcWhenRounded}
      text={text}
    />
  )
}

export function FacebookButton({
  imageSrc = "./f_logo.png",
  imageSrcWhenRounded = "https://frontend-assets.buildbox.com/Icon_FB_white.svg",
  text = "Continue with Facebook",
  className,
  ...otherProps
}: SpecificSocialButtonProps<SocialSignInNetwork.Facebook>): ReactElement {
  return (
    <SocialButton
      {...otherProps}
      className={[styles.facebookButton, className]}
      network={SocialSignInNetwork.Facebook}
      imageSrc={imageSrc}
      imageSrcWhenRounded={imageSrcWhenRounded}
      text={text}
    />
  )
}
