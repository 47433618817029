import { ReactElement, useEffect } from "react";
import { BBAuthClient, BBAuthClientBusyError } from "../../BBAuthClient";
import { Container } from "../../Components/Container";
import { Ellipsis } from "../../Components/Ellipsis";

export function LogoutFlow(props:{authClient:BBAuthClient, redirectPath?:string}):ReactElement {
  const {authClient, redirectPath=""} = props

  useEffect(()=> {
    authClient.logout()
      .then(()=> {
        window.location.pathname = redirectPath
      })
      .catch((e)=> {
        if (e instanceof BBAuthClientBusyError) {
          console.debug(`[LogoutFlow].useEffect] already logging out - ignoring second useEffect call`)
        }
        else {
          console.error(`[LogoutFlow].useEffect] got error while trying to log out: ${e.message}`)
          console.error(e)
        }
      })
  }, [authClient, redirectPath])

  return (
    <Container>
      Logging you out <Ellipsis />
    </Container>
  )
}
