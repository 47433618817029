import { ReactElement, useState } from "react";
import { Container } from "../../Components/Container";
import { Input } from "../../Components/Input";
import { Button } from "../../Components/Button";
import { Flexed } from "../../Components/Flexed";

export function ConfirmEmail(props:{
  onConfirmCode: (confirmationCode:string)=>any
  onAlreadyConfirmed: ()=>any
}):ReactElement {
  const {onConfirmCode, onAlreadyConfirmed} = props
  const [confCode, setConfCode] = useState<string>("")

  function handleConfCodeSubmit() {
    onConfirmCode(confCode)
  }

  function handleAlreadyConfirmed() {
    onAlreadyConfirmed()
  }

  return (
    <Container>
      <form>
        <Flexed vertical>
          <p>Enter the confirmation code you received in your email.</p>
          <Input placeholder="Code from your email" value={confCode} onChange={setConfCode} />
          <Button rounded onClick={handleConfCodeSubmit}>submit</Button>
        </Flexed>
      </form>
      <p>- or -</p>
      <p>Click here if you already confirmed your email in a different tab</p>
      <Button rounded onClick={handleAlreadyConfirmed}>Already Confirmed</Button>
    </Container>
  )
}