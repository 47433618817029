import { ReactElement, ReactNode } from "react";

import styles from "./Button.module.css"
import classnames from "classnames";
import { StyleType } from "./ComponentTypes";

type Props = {
  type?:StyleType
  hidden?: boolean
  className?: string,
  block?: boolean,
  disabled?: boolean,
  rounded?: boolean,
  children?: ReactNode|ReactNode[]|undefined
  onClick?: ()=>void
}
export function Button({hidden=false, type=StyleType.default, className, disabled=false, rounded=false, block=false, children, onClick}:Props):ReactElement|null {
  if (hidden) {
    return null
  }

  function handleOnClick() {
    if (onClick !== undefined && !disabled) {
      onClick()
    }
  }

  return (
    <button
      type={"button"}
      className={
        classnames(
          className,
          styles.button,
          {
            [styles.block]: block,
            [styles.disabled]: disabled,
            [styles.rounded]: rounded
          },
          [styles[type]]
        )}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {children}
    </button>
  )
}