import { ReactElement } from "react";
import { Container } from "../../Components/Container";
import { Button } from "../../Components/Button";

type Props = {
  message?:string|null|undefined
  onTryAgain?:()=>void
}
export function LoginError({message="We're sorry! Something went wrong.", onTryAgain}:Props):ReactElement {
  return (
    <Container>
      <h2>Unable to Login</h2>
      {message}
      <Button rounded onClick={onTryAgain} block>Try again?</Button>
    </Container>
  )
}