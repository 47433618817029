import { ReactElement, useEffect, useState } from "react";
import { BBAuthClient } from "../../BBAuthClient";
import { Container } from "../../Components/Container";
import { Ellipsis } from "../../Components/Ellipsis";

export interface Props {
  authClient: BBAuthClient;
}
export function LoginRedirectFlow({ authClient }: Props): ReactElement {
  const urlParams = new URLSearchParams(window.location.search);
  const [code] = useState<string | null>(urlParams.get("code"));

  const [displayMessage, setDisplayMessage] =
    useState<string>("logging you in ");

  useEffect(() => {
    if (code !== null && !authClient.codeAlreadyConumed(code)) {
      console.debug(`[RedirectFlow] attempting to exchange code for token`);
      authClient
        .codeForTokens(code as string)
        .then((result) => authClient.sendAuthResult(result))
        .catch((e) => {
          console.error(
            `[RedirectFlow]useEffect] error caught during auth flow ${e.message}`
          );
          return authClient.sendAuthResult({
            successful: false,
            message:
              "We ran into some issues while logging you in. Please try again or contact customer support.",
            IdToken: null,
            AccessToken: null,
            RefreshToken: null,
            isGuest: null,
          });
        })
        .then(() => {
          console.debug("[RedirectFlow] closing...");
          setDisplayMessage("Done. Closing this tab");
          // this timeout isn't really necessary as authClient will wait for the parent to acknowledge receipt before resolving
          //    BUT it seemed like a good thing to do?
          setTimeout(() => window.close(), 500);
        });
    } else if (code !== null) {
      console.debug(
        `[RedirectFlow] rendering after message sent - not resending`
      );
    }
    // else - we're in logout mode presumably
  }, [code, authClient]);

  return (
    <Container>
      <span>
        {displayMessage}
        <Ellipsis />
      </span>
    </Container>
  );
}
