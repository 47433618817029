export class Logger {
    constructor(instanceLabel = "") {
        this.instanceLabel = instanceLabel;
    }
    logMsg(...params) {
        return `${this.instanceLabel} ${params.join(" ")}`;
    }
    debug(...params) {
        console.debug(this.instanceLabel, ...params);
    }
    info(...params) {
        console.info(this.instanceLabel, ...params);
    }
    log(...params) {
        console.log(this.instanceLabel, ...params);
    }
    warn(...params) {
        console.warn(this.instanceLabel, ...params);
    }
    error(...params) {
        console.error(this.instanceLabel, ...params);
    }
    methodLogger(methodName) {
        return new Logger(`${this.instanceLabel}.${methodName}]`);
    }
}
