import { ReactElement, ReactNode } from "react";
import classnames from "classnames";

import styles from "./Container.module.css"

type Props = {
  className?:string|undefined
  logo?:boolean|ReactNode
  children?:ReactNode|ReactNode[]|string|undefined
}
export function Container({children,logo=false,className}:Props):ReactElement {
  let logoEle:undefined|ReactNode = undefined
  if (logo) {
    logoEle = typeof logo === "boolean"? <img key={"logo"} src={"https://frontend-assets.buildbox.com/uploads/2023_05/buildboxlogo.png"} alt="Buildbox logo" /> : logo
  }

  return (
    <div className={classnames(className,styles.background)}>
      <div className={styles.container}>
        {logoEle}
        {children}
      </div>
    </div>
  )
}