import { ReactElement, useEffect, useState } from "react";

function incrementEllipsis(current:string):string {
  current += "."
  if (current.length > 3) {
    return ""
  }
  else {
    return current
  }
}

export type Props = {
  className?:string
  width?: string
  interval?: number
}

export function Ellipsis({className, interval=500, width="2em"}:Props):ReactElement {
  const [ellipsis, setEllipsis] = useState<string>("...")

  useEffect(()=>{
    setTimeout(()=>{
      setEllipsis(incrementEllipsis(ellipsis))
    }, interval)
  }, [interval, ellipsis, setEllipsis])

  return (
    <span className={className} style={{display:"inline-block", width}}>{ellipsis}&nbsp;</span>
  )
}