import React, { ReactElement, ReactNode } from "react";


export type RouteProps = {
  path: string,
  children:ReactNode|ReactNode[]|undefined
}
export function Route({children}:RouteProps):ReactElement {
  const childrenArr = Array.isArray(children)? children : [children]
  return (<>
    {childrenArr}
  </>)
}

type RouteElement = ReactElement<RouteProps, typeof Route>

export type RouterProps = {
  path?: string,
  children: RouteElement[]|RouteElement|undefined
}
export function SimpleRouter({path=window.location.pathname, children}:RouterProps):ReactElement|null {
  if (children === undefined) {
    return null
  }
  const childrenArr = Array.isArray(children)? children : [children]

  const childEles =  React.Children.map(childrenArr, (child) => {
    if (child.props.path===path) {
      return child
    }
    else {
      return undefined
    }
  })

  return <>
    {childEles}
  </>
}
