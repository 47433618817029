import { Logger } from "./Logger";
const VERSION = "1.5.2";
const CHILD_LOAD_TIMEOUT_MS = 500;
const ORPHAN_RECOVERY_BROADCAST_TIMEOUT_MS = 20 * 1000;
// subdomains not listed here that have matching protocol and ports will be considered allowed by the isAlllowedOrigin method of this class and the postMessage API
// AKA subdomains are allowed implicitly
const PROD_ALLOWED_ORIGINS = [
    "https://buildboxworld.com",
    "https://buildbox.com",
].map((url) => new URL(url));
const DEV_ALLOWED_ORIGINS = [
    "https://8cell.com",
    "http://localhost:8888",
    "http://localhost:3000",
].map((url) => new URL(url));
export var MessageTypes;
(function (MessageTypes) {
    MessageTypes["MESSAGE_RECEIVED"] = "MESSAGE_RECEIVED";
    MessageTypes["REGISTER_ORIGIN"] = "REGISTER_PARENT";
    MessageTypes["AUTH_RESULT"] = "AUTH_RESULT";
    MessageTypes["AUTH_CODE"] = "AUTH_CODE";
})(MessageTypes || (MessageTypes = {}));
export var MessageStatus;
(function (MessageStatus) {
    MessageStatus["RECEIVED"] = "RECEIVED";
    // TIMEDOUT = "TIMEDOUT",
    MessageStatus["ERROR"] = "ERROR";
})(MessageStatus || (MessageStatus = {}));
/**
 * A wrapper class/client for communicating between two buildbox websites either embedded, or across tabs
 */
export class BBPostMessageClient {
    constructor(environment = "prod", childFrame, childOrigin, parentIsOpener = false) {
        this.badConnection = false;
        this.recieverFrame = null;
        this.recieverOrigin = null;
        this.listeners = {};
        this.pendingCallbacks = {};
        this.pendingReady = [];
        this.readyTimeout = null;
        this.orphanRecoveryTimeout = null;
        // if provided with a childFrame it implies that "we" are the parent frame
        this.isParent = childFrame !== null;
        this.parentIsOpener = parentIsOpener;
        // this is handy for knowing what instance logs are coming from
        this.instanceLabel = `[BBpmc ${this.isParent ? "parent" : "child"}-${String.fromCharCode(Math.random() * 23 + 65)}${String.fromCharCode(Math.random() * 23 + 65)}]`; // just used for debugging purposes
        this.logger = new Logger(this.instanceLabel);
        this.logger.debug(`[BBPostMessageClient] version:${VERSION}] origin:${window.origin} instanceLabel: ${this.instanceLabel}`);
        // pick the allowed origins based on environment type
        const env = environment.toLowerCase();
        if (env === "prod") {
            this.allowedOrigins = PROD_ALLOWED_ORIGINS;
        }
        else if (env === "dev" || env === "local") {
            this.allowedOrigins = DEV_ALLOWED_ORIGINS;
        }
        else {
            throw new Error("Unknown environment mode: " + environment);
        }
        // add a safeguard against trying to use this class in an unknown origin
        if (!this.isAllowedOrigin(window.origin)) {
            throw new Error(this.logger.logMsg("Can't send messages from this origin!"));
        }
        // setup listeners
        this.addListener(MessageTypes.MESSAGE_RECEIVED, (message) => this.handleReceiptMessage(message));
        window.addEventListener("message", (message) => this.handleMessage(message));
        // if parent, we need to some extra setup
        if (this.isParent) {
            this.recieverFrame = childFrame;
            this.recieverOrigin = childOrigin;
            if (this.parentIsOpener) {
                this.isReady = true; // we're just going to assume we can start firing messages right away
            }
            else {
                this.readyTimeout = setTimeout(() => this.handleChildLoad(), CHILD_LOAD_TIMEOUT_MS);
            }
        }
        else if (this.parentIsOpener) {
            // if a child, but was opened instead of embedded, we're going to assume same origin
            //    AND that we can't assume the child will be ready for communication anytime soon (i.e. google auth flow)
            // FUTURE IMPROVEMENT: The assumptions above should be behind their own flag
            //    AND the configuration is getting too complicated - will need a MODE or cleaner config settings
            this.recieverFrame = window.opener;
            this.recieverOrigin = window.origin;
            this.isReady = true; // we're just going to assume we can start firing messages right away
        }
        // otherwise, we need to wait for the parent frame to register with us
        else {
            this.addListener(MessageTypes.REGISTER_ORIGIN, (message) => this.handleRegisterParent(message));
        }
    }
    /**
     * This must be called by the instantiator for mysterious CORS reasons
     */
    handleChildLoad() {
        clearTimeout(this.readyTimeout);
        if (!this.isAllowedOrigin(this.recieverOrigin)) {
            throw new Error(this.logger.logMsg("Child origin is not on the allowed list.", this.recieverOrigin));
        }
        this.logger.debug(`.handleChildLoad]`);
        if (this.isReady) {
            this.logger.warn(`.handleChildLoad] called when instance is already ready - ignoring`);
            return;
        }
        else if (this.badConnection) {
            this.logger.error(`.handleChildLoad] bad connection detected - can't do anything - ignoring`);
            return;
        }
        else if (this.badConnection) {
            this.logger.error(`.handleChildLoad] bad connection detected - can't do anything - ignoring`);
            return;
        }
        // we need to register with the child frame before they can send messages to us
        this.sendMessage({
            messageType: MessageTypes.REGISTER_ORIGIN,
            payload: {
                origin: window.origin, // we want to send our origin NOT the childs origin
            },
        })
            .then((result) => {
            if (result.status === MessageStatus.RECEIVED) {
                clearTimeout(this.readyTimeout);
                this.isReady = true;
                if (this.isParent && !this.parentIsOpener) {
                    this.handleRecoverOrphanBroadcast();
                }
            }
            else {
                this.logger.error(`Could not connect to child frame: ${this.recieverOrigin}`);
                this.logger.error(result.errorMessage);
                this.badConnection = true;
            }
        })
            .catch((e) => this.logger.error("OH NOE", e.message));
        this.readyTimeout = setTimeout(() => {
            this.logger.warn(`.handleChildLoad - timeout] register parent message timed out after ${CHILD_LOAD_TIMEOUT_MS}. Trying again`);
            this.handleChildLoad();
        }, CHILD_LOAD_TIMEOUT_MS);
    }
    handleRecoverOrphanBroadcast(skipFirst = true) {
        if (!skipFirst) {
            this.logger.debug(`.handleRecoverOrphanBroadcast] broadcasting register parent in case there are children clients created after the initial connection to this parent finished.`);
            this.sendMessage({
                messageType: MessageTypes.REGISTER_ORIGIN,
                payload: {
                    origin: window.origin, // we want to send our origin NOT the childs origin
                },
            });
        }
        else {
            this.logger.debug(`.handleRecoverOrphanBroadcast] skipping first attempt at recovery.`);
        }
        // heads up! ASYNCHRONOUS
        this.orphanRecoveryTimeout = setTimeout(() => {
            this.handleRecoverOrphanBroadcast(false);
        }, ORPHAN_RECOVERY_BROADCAST_TIMEOUT_MS);
    }
    /**
     * Should be called when this client will no longer be used
     * - removes "message" listeners from the window object
     */
    unregister() {
        this.logger.debug(`.unregister] ${origin}`);
        clearTimeout(this.readyTimeout);
        clearTimeout(this.orphanRecoveryTimeout);
        window.removeEventListener("message", this.handleMessage);
        this.listeners = {};
        this.pendingCallbacks = {};
        this.pendingReady = [];
    }
    waitFor(messageType) {
        // *** fun with closures ***
        let resolveRef;
        const listener = (message) => {
            this.removeListener(messageType, listener);
            resolveRef(message.payload);
        };
        return new Promise((resolve) => {
            resolveRef = resolve;
            // TODO - timeout / reject cases
            this.addListener(messageType, listener);
        });
    }
    /**
     * from: https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#syntax
     * If at the time the event is scheduled to be dispatched the scheme, hostname, or port of this window's document does not match that provided in targetOrigin, the event will not be dispatched; only if all three match will the event be dispatched.
     * @param origin
     */
    isAllowedOrigin(origin) {
        const originUrl = new URL(origin);
        for (let allowed of this.allowedOrigins) {
            const protocolsMatch = originUrl.protocol === allowed.protocol;
            const hostIsMatchOrSubdomain = originUrl.hostname.endsWith(allowed.hostname);
            const portsMatch = originUrl.port === allowed.port;
            if (protocolsMatch && hostIsMatchOrSubdomain && portsMatch) {
                return true;
            }
        }
        return false;
    }
    validateMessage(message) {
        if (typeof message.messageType !== "string") {
            console.warn("[BBPostMessage.validateMessage] recieved message with undefined message type");
            return null;
        }
        // TODO
        return message;
    }
    addListener(messageType, listener) {
        if (!(messageType in this.listeners)) {
            this.listeners[messageType] = [];
        }
        this.listeners[messageType].push(listener);
    }
    removeListener(messageType, listener) {
        const listeners = this.getListeners(messageType);
        const filtered = listeners.filter((existing) => existing !== listener);
        if (filtered.length === listeners.length - 1) {
            this.listeners[messageType] = filtered;
            return listener;
        }
        else {
            return null;
        }
    }
    getListeners(messageType) {
        if (!(messageType in this.listeners)) {
            return [];
        }
        else {
            return this.listeners[messageType];
        }
    }
    sendMessageReceived(message) {
        const receipt = {
            messageType: MessageTypes.MESSAGE_RECEIVED,
            id: message.id,
            // attempt: message.attempt,
            payload: message,
        };
        this.recieverFrame.postMessage(receipt, this.recieverOrigin);
    }
    handleMessage({ origin, data }) {
        if (!this.isAllowedOrigin(origin)) {
            this.logger.debug("ignoring message from", origin);
            return; // ignore messages not from a recognized origin (i.e. stripe.js tends to send a lot of messages)
        }
        const { messageType } = data;
        const listeners = this.getListeners(messageType);
        const read = [];
        for (let listener of listeners) {
            try {
                listener(data);
                if (messageType !== MessageTypes.MESSAGE_RECEIVED) {
                    // Don't send an received event for a received event unless you want an infinite loop
                    read.push(data);
                }
            }
            catch (e) {
                this.logger.error(`Client][handleMessage] listener threw an error when called: ${listener.name} threw: ${e.message}`);
            }
        }
        read.map((message) => this.sendMessageReceived(message));
    }
    handleReceiptMessage(message) {
        if (!(message.id in this.pendingCallbacks)) {
            this.logger.debug(`.handleReceiptMessage] got message receipt for unknown message ${message.messageType}-id:${message.id}-attempt-no:${ /*message.attempt*/"Not.Imp."}`);
            return;
        }
        const pendingCallback = this.pendingCallbacks[message.id];
        delete this.pendingCallbacks[message.id];
        if (!pendingCallback.cancelled) {
            pendingCallback.resolve({
                status: MessageStatus.RECEIVED,
                originalMessage: message.payload,
                errorMessage: null,
            });
        }
        else {
            this.logger.debug(`.handleReceiptMessage] ignoring receipt message with cancelled status ${message.id}`);
        }
    }
    /**
     * Attempt to resolve a message that was sent before this instance was ready
     *
     * Takes a pending message, sends it, and "wires up" the saved promise callbacks
     * to the new promise created by calling sendMessage
     * @param pending
     */
    handlePendingReady(pending) {
        const { message, callback: { resolve, reject }, } = pending;
        this.sendMessage(message).then(resolve).catch(reject);
    }
    getParentFrame() {
        if (this.parentIsOpener) {
            this.logger.debug(`.getParentFrame] parentIsOpener is true - using window.opener instead of window.parent to get parent frame`);
            const opener = window.opener;
            if (opener === null) {
                throw new Error(this.logger.logMsg(`.getParentFrame] window.opener is null and parentIsOpener flag is true! Connection can't be established`));
            }
            return opener;
        }
        else {
            return window.parent;
        }
    }
    /**
     * If we receive a REGISTER_PARENT event, we need to save the parent frame and origin,
     * and send any messages that were waiting for this event
     * @param param0
     * @returns
     */
    handleRegisterParent({ payload: { origin }, }) {
        if (this.isParent) {
            this.logger.debug(`.handleRegisterParent] ignoring attempt to register ${origin} as parent to origin with existing parent ${this.recieverOrigin}`);
            return;
        }
        this.logger.debug(`.handleRegisterParent] got parent register event ${origin}`);
        this.recieverFrame = this.getParentFrame();
        this.recieverOrigin = origin;
        // lets wait one JS execution cycle before firing these off
        setTimeout(() => {
            this.isReady = true;
            const ready = this.pendingReady;
            this.pendingReady = [];
            ready.forEach((pending) => this.handlePendingReady(pending));
        }, 1);
    }
    makeId() {
        return `${this.instanceLabel}---${Date.now()}---${Math.floor(Math.random() * 1000)}`; // a collision is still probably possible with this format, but that won't be the end of the world
    }
    sendMessage(message) {
        if (this.badConnection) {
            return Promise.reject(new Error(`Can not send message ${message.messageType} because connection to child frame is broken.`));
        }
        if (message.messageType !== MessageTypes.REGISTER_ORIGIN && !this.isReady) {
            // if we aren't ready, and this message isn't being sent to get us ready, return
            return new Promise((resolve, reject) => {
                this.pendingReady.push({
                    message,
                    callback: { cancelled: false, resolve, reject },
                });
            });
        }
        let sentMessage = message;
        if (sentMessage.id === undefined) {
            sentMessage = {
                ...message,
                id: this.makeId(),
                // attempt: 1
            };
        }
        // if ((message.retries === undefined && sentMessage.attempt > 1) || sentMessage.attempt >= message.retries) {
        //   return Promise.reject()
        // }
        return new Promise((resolve, reject) => {
            this.pendingCallbacks[sentMessage.id] = {
                cancelled: false,
                resolve,
                reject,
            };
            // if (message.timeout !== undefined) {
            //   setTimeout(()=>this.sendMessage(origin, target, {...sentMessage, attempt:sentMessage.attempt+1}), message.timeout)
            // }
            try {
                this.logger.debug(`.sendMessage] sending ${sentMessage.messageType}---id:${sentMessage.id}`);
                this.recieverFrame.postMessage(sentMessage, this.recieverOrigin);
            }
            catch (e) {
                this.logger.error(`.sendMessage] Error while sending message ${sentMessage.messageType}---id:${sentMessage.id}`);
                this.logger.error(e.message);
                this.logger.error(e.stack);
                reject({
                    originalMessage: sentMessage,
                    errorMessage: e.message,
                    status: MessageStatus.ERROR,
                });
            }
        });
    }
}
