import { ReactElement, useEffect, useState } from "react";
import { Container } from "../../Components/Container";
import { Ellipsis } from "../../Components/Ellipsis";
import { BBAuthClient, BBAuthClientBusyError } from "../../BBAuthClient";

async function handleGuestLogin(client:BBAuthClient):Promise<void> {
  const result = await client.authenticateAsGuest()
  if (result.successful) {
    console.debug(`[BBAuthFrame][GuestFlow].handleGuestLogin] successfully logged in as guest. Sending result to parent.`)
  }
  else {
    console.error(`[BBAuthFrame][GuestFlow].handleGuestLogin] Unable to login as guest - redirecting to normal login page.`)
  }
  await client.sendAuthResult(result)
}

/**
 * Attempt to login as a guest and redirect to normal login on failure
 * @param {Props} props
 * @returns 
 */
export function GuestFlow(props:{authClient:BBAuthClient}): ReactElement {
  const {authClient} = props
  const [errorMessage, setErrorMessage] = useState<string|null>(null)

  useEffect(() => {
    handleGuestLogin(authClient)
      .catch((e) => {
        if (e instanceof BBAuthClientBusyError) {
          console.debug(`[GuestFlow].useEffect] already authing as guest - ignore double useEffect`)
        }
        else {
          console.error(`[Caught error while attempting to auth as guest] ${e.message}`)
          console.error(e)
        }
      })
  }, [authClient,setErrorMessage])

  return (
    <Container logo={true}>
      {errorMessage === null? <>Creating a guest account for you <Ellipsis /></> : undefined }
      {errorMessage !== null? <>{errorMessage}</> : undefined }
    </Container>
  )
}
