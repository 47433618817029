import { ReactElement } from "react";

export interface Props {
  condition:boolean
  children?: ReactElement[]|ReactElement|string|null
}
export function ConditionalRender({condition, children=null}:Props):ReactElement|null {
  if (!condition) {
    return null
  }
  else if (Array.isArray(children) || typeof children === "string") {
    return <>{children}</>
  }
  else {
    return children
  }
}
