export function getRequiredEnvVar(envVarName: string, defaultVal?:string): string {
  const varName = `REACT_APP_${envVarName}`
  const val = process.env[varName]
  if (val === undefined) {
    if (defaultVal !== undefined) {
      return defaultVal
    }
    else {
      throw new Error(`[Env] config setting: ${varName} NOT FOUND`)
    }
  }
  return val
}

export function getRequiredIntEnvVal(envVarName:string, defaultVal:number): number {
  const strVal = getRequiredEnvVar(envVarName, defaultVal.toString(10))
  const numVal = parseInt(strVal, 10)
  if (isNaN(numVal)) {
    throw new Error(`[Env] config setting: REACT_APP_${envVarName} must be an integer value.`)
  }
  return numVal
}

/**
 * Return the appropriate value based on the existence of an env var with that name OR
 * one of the passed-in values depending on the value of AOB_ENV
 * 
 * @param overrideEnvVarName the name of the environment variable that would contain an override value WITHOUT the REACT_APP_ prefix. If a value is found, that value will be used instead of any values passed into this function
 * @param prodValue will be used if there is no environment variable found AND AOB_ENV === PROD OR AOB_ENV is not recognized
 * @param {string} devValue will be used if there is no environment variable found AND (AOB_ENV === DEV OR (AOB_ENV === LOCAL AND no localValue provided))
 * @param {string?} localValue optional - will be used if defined AND there is no environment variable found AND AOB_ENV === LOCAL
 * @returns 
 */
export function getEnvVarForEnvironment(overrideEnvVarName:string|null, prodValue:string, devValue:string, localValue?:string):string {
  let defaultVal:string
  const AOB_ENV = getRequiredEnvVar("AOB_ENV", "PROD").toUpperCase()
  if (AOB_ENV === "LOCAL") {
    if (localValue !== undefined) {
      defaultVal = localValue
    }
    else {
      defaultVal = devValue
    }
  }
  else if (AOB_ENV === "DEV") {
    defaultVal = devValue
  }
  else {
    if (AOB_ENV !== "PROD") {
      console.error(`[Env] Unrecognized environment name ${AOB_ENV} defaulting to PROD value`)
    }
    defaultVal = prodValue
  }
  if (overrideEnvVarName !== null) {
    return getRequiredEnvVar(overrideEnvVarName, defaultVal)
  }
  else {
    return defaultVal
  }
}

/**
 * A map of all the config / environment variables that this web app uses
 */
export const Env = {
  AOB_ENV: getRequiredEnvVar("AOB_ENV", "PROD"),
  COG_URL: getRequiredEnvVar("COG_URL", `https://cognito-idp.us-east-1.amazonaws.com`),

  ACCESS_TIMEOUT_MILLISECONDS: getRequiredIntEnvVal("ACCESS_TIMEOUT_MILLISECONDS", 15 * 60 * 1000),
  BB_AUTH_DOMAIN: getEnvVarForEnvironment("BB_AUTH_DOMAIN", "https://bb-auth.buildbox.com", "https://bb-auth.dev.8cell.com", "http://localhost:8888"),
  COG_CLIENT_ID: getEnvVarForEnvironment("COG_CLIENT_ID", "5tamhcn07jpk28maluh9ss6hq2", "2lpff3g8hh1v2facdl862nnstr"),
  COG_DOMAIN: getEnvVarForEnvironment("COG_DOMAIN", "https://login.buildbox.com", "https://cognito-login.dev.8cell.com"),
  UAM_BASE: getEnvVarForEnvironment("UAM_BASE", "https://platform.buildbox.com", "https://useraccountmanagement.dev.8cell.com"),
}
