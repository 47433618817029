import { ReactElement, ReactNode } from "react"

import styles from "./Flexed.module.css"
import classnames from "classnames"

export interface Props {
  className?: string
  vertical?: boolean
  between?: boolean
  centered?: boolean
  // please add any options you think are missing for making flex boxes easy to configure

  children: ReactNode|ReactNode[]|undefined|null
}

export function Flexed (props:Props):ReactElement {
  const {
    className,

    vertical=false,
    between=false,
    centered=false,
    children
  } = props
  const classFlags = {
    [styles.vertical]: vertical,
    [styles.between]: between,
    [styles.centered]: centered
  }

  return (
    <div className={classnames(styles.flexed, className, classFlags)}>
      {children}
    </div>
  )
}