import { ReactElement, useEffect, useState } from 'react';
import { Login } from './Login';
import { AuthPhase, BBAuthClient, BBAuthClientBusyError, SocialSignInNetwork } from "../../BBAuthClient";
import { LoginResult } from "bb-auth-frame/bb-post-message"
import { LoginError } from './LoginError';
import { Container } from '../../Components/Container';
import { Ellipsis } from '../../Components/Ellipsis';
import { Flexed } from '../../Components/Flexed';
import { Button } from '../../Components/Button';

import styles from "./LoginFlow.module.css"
import { ConfirmEmail } from './ConfirmEmail';

export interface AuthState {
  status:"refreshing"|"open"|"confirming"|"working"|"error"|"done"
  message:string|null
}

export interface Props {
  startInCreateMode?:boolean
  compact?:boolean
  authClient:BBAuthClient
}
export function LoginFlow({authClient, startInCreateMode=false, compact=false}:Props):ReactElement {
  const [authState,setAuthState] = useState<AuthState>({status:"refreshing", message:null})
  const [username, setUsername] = useState<string|null>(null)
  const [password, setPassword] = useState<string|null>(null)

  // function setAuthState(state:AuthState) {
  //   console.warn(`[SetAuthStatue] ${state.status} - "${state.message}"`)
  //   _setAuthState(state)
  // }

  function handleAuthPhaseMessage(phase:AuthPhase, message:string) {
    console.debug(`[LoginFlow].handleAuthPhaseMessage]${phase}]`)
    setAuthState({status:"working", message})
  }

  async function handleAuth(authFunction:()=>Promise<LoginResult>):Promise<void> {
    if (authState.status === "working") { // TODO - better indicator of a double auth call
      console.warn("[LoginFlow] handleAuth called twice - ignoring second")
      return // we are already trying - don't send again - should probably bubble this up a level
    }
    setAuthState({
      ...authState,
      status: "working",
    })
    try {
      const result = await authFunction()
      if (result !== null && result.successful) {
        setAuthState({status: "working", message:"Letting the website know who you are"})
        await authClient.sendAuthResult(result)
        setAuthState({
          status: "done",
          message: null
        })
      }
      else {
        const resultMessage = result !== null? result.message : "Something went wrong with verifying your log-in info."
        setAuthState({
          status: "error",
          message: resultMessage
        })
      }
    }
    catch (e:any) {
      if (e instanceof BBAuthClientBusyError) {
        console.debug(`[LoginFlow] BBAuthClient busy thrown while handling auth result - ignoring`)
      }
      else {
        console.error(`[LoginFlow] Caught error from authClient ${e?.message}`)
        console.error(e)
        setAuthState({
          status: "error",
          message: "We're experiencing some technical difficulties. Please try again later."
        })
      }
    }
  }

  function handleLogOut():void {
    authClient.logout()
  }

  function handleLogin(userName:string,password:string):Promise<void> {
    return handleAuth(async ()=> {
      const result = await authClient.loginWithCognito(userName, password, true, false, handleAuthPhaseMessage)
      if (result.successful) {
        console.debug(`[LoginFlow] login was successful - atempting to transfer guest account`)
        await authClient.transferGuest(result.AccessToken, handleAuthPhaseMessage)
          .then(result => new Promise(resolve => setTimeout(() => resolve(result), 5000)))
      }
      return result
    })
  }

  async function handleCreateAccount(name:string, email:string, userName:string,password:string):Promise<void> {
    setAuthState({
      status: "working",
      message: "Creating your account"
    })
    setUsername(userName)
    setPassword(password)
    try {
      await authClient.signup(name, email, userName, password)
    }
    catch(e) {
      console.error(`[LoginFlow].handleCreateAccount] error occurred during signup`)
      console.error(e)
      setAuthState({
        status: "error",
        message: "Something went wrong while we were creating your account."
      })
      return
    }
    setAuthState({
      status: "confirming",
      message: null
    })
  }

  async function handleConfirmCode(confirmationCode:string):Promise<void> {
    if (username !== null && password !== null) {
      return handleAuth(()=>authClient.confirm(username, password, confirmationCode, handleAuthPhaseMessage))
    }
    else {
      console.error("confirmation code submitted when username and password were not set")
      setAuthState({
        status: "error",
        message: "Something went wrong while processing your confirmation code."
      })
    }
  }

  function handleAlreadyConfirmed():Promise<void> {
    setAuthState({
      status: "working",
      message: "Checking"
    })
    if (username !== null && password !== null) {
      return handleAuth(()=>authClient.confirm(username, password, null, handleAuthPhaseMessage))
    }
    else {
      console.error("confirmation code submitted when username and password were not set")
      setAuthState({
        status: "error",
        message: "Something went wrong while processing your confirmation code."
      })
      return Promise.resolve()
    }
  }

  function handleSocialClick(network:SocialSignInNetwork):Promise<void> {
    return handleAuth(()=> authClient.loginWithSocial(network, handleAuthPhaseMessage))
  }

  function handleRetryClick() {
    setAuthState({
      status: "open",
      message: null
    })
  }

  useEffect(() => {
    authClient.trySilentAuth(false)
      .then(async (result) => {
        if (result.successful) {
          setAuthState({
            status: "working",
            message: "Letting the website know who you are"
          })
          await authClient.sendAuthResult(result)
          setAuthState({
            status: "done",
            message: null
          })
        }
        else {
          setAuthState({
            status: "open",
            message: null
          })
        }
      })
      .catch((e) => {
        if (e instanceof BBAuthClientBusyError) {
          console.debug(`[LoginFlow].useEffect] already refreshing - ignore double useEffect`)
        }
        else {
          console.error(`[Caught error while attempting to refresh] ${e.message}`)
          console.error(e)
        }
      })
  }, [authClient, setAuthState])

  return (
    <div className="App">
      {authState.status === "refreshing"? <Container>
        <Flexed vertical centered>
          Looking for an existing account <Ellipsis className={styles.ellipsis} width={"unset"}/>
          {/* <Button block className={styles.refreshingLogoutButton} onClick={handleLogOut}>Log-out</Button> */}
        </Flexed>
      </Container> : undefined}
      {authState.status === "open"? <Login startInCreateMode={startInCreateMode} compact={compact} onLogin={handleLogin} onCreateAccount={handleCreateAccount} onSocialClick={handleSocialClick} />: undefined}
      {authState.status === "confirming"? <ConfirmEmail onConfirmCode={handleConfirmCode} onAlreadyConfirmed={handleAlreadyConfirmed} /> : undefined}
      {authState.status === "working"? <Container>{authState.message !== null? authState.message : "Loading"}<Ellipsis/></Container>: undefined}
      {authState.status === "error"? <LoginError message={authState.message} onTryAgain={handleRetryClick} />: undefined}
      {authState.status === "done"? <Container><Button rounded onClick={handleLogOut}>Log-out</Button></Container> : undefined}
    </div>
  );
}
